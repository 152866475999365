<template>
  <b-card-code title="Ajout d'un jour ferié">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de jour ferié </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <label for="example-datepicker">Date</label>
            <b-form-datepicker
              v-model="date"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Label"
              label-for="Label"
            >
              <validation-provider
                #default="{ errors }"
                name="Label"
                rules="required"
              >
                <b-form-input
                  v-model="label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Label"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addEmployee"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    // vSelect,
  },
  data() {
    return {
      date: '',
      label: '',
      required,
    }
  },

  created() {
  },
  methods: {

    async addEmployee() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/holidays/create/',
                {
                  dated: this.date,
                  label: this.label,
                },
              )
            this.$router.push('/JourFerie')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Jour Ferie ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
